import { graphql } from 'gatsby';
import ContentPageTemplate from './contentPage';

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentSeminarPageQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      headerBackground
      pageType {
        ... on ContentfulContentPageType {
          content {
            ...ContentFragment
          }
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
    contentfulBlogPageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    allSeminars: allSeminarsJson {
      nodes {
        id
        name
        type
        topics
        datetime {
          start
          end
          date
        }
        lang
        jsonId
        location {
          name
        }
        private
      }
    }
  }
`;
